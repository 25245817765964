import React from "react";
import { useHistory } from "react-router-dom";

const ContactInfo = () => {
  const history = useHistory();
  const handleClickEmail = () => {
    history.push("envoyer-email");
  };

  return (
    <div className="contactInfo container">
      <div className="row">
        <div className="col-12 col-md-4 contact-Box">
          <div className="box-info">
            <div className="info-image">
              <i className="fas fa-phone-alt"></i>
            </div>
            <h5>Contact commercial</h5>
            <p>034 39 797 78</p>
            <p>038 92 74259</p>
          </div>
        </div>
        <div className="col-12 col-md-4 contact-Box">
          <div className="box-info">
            <div className="info-image">
              <i className="fas fa-map-marker-alt"></i>
            </div>
            <h5>Siège social</h5>
            <p>Mahamasina, Analamanga</p>
            <h5>Atelier</h5>
            <p>Ambatomaro</p>
          </div>
        </div>
        <div
          className="col-12 col-md-4 contact-Box contact-email"
          onClick={handleClickEmail}
        >
          <div className="box-info">
            <div className="info-image">
              <i className="fas fa-envelope"></i>
            </div>
            <h5>E-mail</h5>
            <p>csm.sifaka.mg@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
