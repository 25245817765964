import axios from "axios";
import {
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_SUCCESS,
} from "../Constants/CategoryConstants";

export const listCategory = () => async (dispatch) => {
  const URL = process.env.REACT_APP_URI
  try {
    dispatch({ type: CATEGORY_LIST_SUCCESS });

    const { data } = await axios.get(`${URL}/api/category`);
    // console.log('COLOR', data)
    dispatch({ type: CATEGORY_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CATEGORY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
