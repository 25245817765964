import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import Message from "../components/LoadingError/Error";
import Loading from "../components/LoadingError/Loading";
import { listFlashsaleDetails } from "../Redux/Actions/FlashsaleActions";
import { Helmet } from 'react-helmet';

const SingleFlashSale = ({ history, match }) => {
  const dispatch = useDispatch();
  const [qty, setQty] = useState(1);

  const flashsaleId = match.params.id;

  const flashsalesDetails = useSelector((state) => state.flashsaleDetails);
  const { loading, error, flashsale } = flashsalesDetails;

  useEffect(() => {
    dispatch(listFlashsaleDetails(flashsaleId));
  }, [dispatch, flashsaleId]);

  const handleAddCart = (event) => {
    event.preventDefault();
    history.push(`/cart/${flashsaleId}?qty=${qty}`);
  };

  return (
    <Box>
      <Helmet>
        <title>sifaka.mg</title>
        <meta
          name="sifaka.mg"
          content="Sifaka ecommerce, Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne, produits artisanals"
        />
        <description>Sifaka ecommerce, Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne, produits artisanals</description>
      </Helmet>
      <Header />
      <div className="container single-product">
        {loading ? (
          <div className="mb-5">
            <Loading />
          </div>
        ) : error ? (
          <Message variant="alert-danger">{error}</Message>
        ) : (
          <>
            <div className="row">
              <div className="col-md-6">
                <div className="single-image">
                  <img src={flashsale?.image} alt={flashsale?.name} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="product-dtl">
                  <div className="product-info">
                    <div className="product-name">{flashsale?.name}</div>
                  </div>
                  <p>{flashsale?.description}</p>

                  <div className="product-count col-lg-7 ">
                    <div className="flex-box d-flex justify-content-between align-items-center">
                      <h6>Prix</h6>
                      <span>{flashsale?.price} Ar</span>
                    </div>
                    <div className="flex-box d-flex justify-content-between align-items-center">
                      <h6>Statut</h6>
                      {flashsale?.countInStock > 0 ? (
                        <span>Dans le stock</span>
                      ) : (
                        <span>invalide</span>
                      )}
                    </div>
                    {/* <div className="flex-box d-flex justify-content-between align-items-center">
                      <h6>Votes</h6>
                      <Rating
                        value={product.rating}
                        text={`${product.numReviews} revue(s)`}
                      />
                    </div> */}
                    {flashsale?.countInStock > 0 ? (
                      <>
                        <div className="flex-box d-flex justify-content-between align-items-center">
                          <h6>Quantité(s)</h6>
                          <select
                            value={qty}
                            onChange={(event) => setQty(event.target.value)}
                          >
                            {[...Array(flashsale?.countInStock).keys()].map(
                              (x) => (
                                <option key={x + 1} value={x + 1}>
                                  {x + 1}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        <button
                          onClick={handleAddCart}
                          className="round-black-btn"
                        >
                          Ajouter au panné
                        </button>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            {/* RATING */}
            {/* <div className="row my-5">
              <div className="col-md-6">
                <h6 className="mb-3">Revue(s)</h6>
                {product?.reviews.length === 0 && (
                  <Message variant={"alert-info mt-3"}>Pas de revue</Message>
                )}

                {product?.reviews.map((review) => (
                  <div
                    key={review._id}
                    className="mb-5 mb-md-3 bg-light p-3 shadow-sm rounded"
                  >
                    <strong>{review?.name}</strong>
                    <Rating value={review?.rating} />
                    <span>{moment(review?.createdAt).calendar()}</span>
                    <div className="alert alert-info mt-3">
                      {review?.comment}
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-md-6">
                <h6>Evaluation</h6>
                <div className="my-4">
                  {loadingCreateReview && <Loading />}
                  {errorCreateReview && <Message>{errorCreateReview}</Message>}
                </div>

                {userInfo ? (
                  <form onSubmit={submitHandler}>
                    <div className="my-4">
                      <strong>Notation</strong>
                      <select
                        value={rating}
                        onChange={(event) => setRating(event.target.value)}
                        className="col-12 bg-light p-3 mt-2 border-0 rounded"
                      >
                        <option value="">Select...</option>
                        <option value="1">1 - Mauvais</option>
                        <option value="2">2 - Acceptable</option>
                        <option value="3">3 - Bien</option>
                        <option value="4">4 - Tres bien</option>
                        <option value="5">5 - Excellent</option>
                      </select>
                    </div>
                    <div className="my-4">
                      <strong>Commentaire</strong>
                      <textarea
                        row="3"
                        value={comment}
                        onChange={(event) => setComment(event.target.value)}
                        className="col-12 bg-light p-3 mt-2 border-0 rounded"
                      ></textarea>
                    </div>
                    <div className="my-3">
                      <button
                        disabled={loadingCreateReview}
                        className="col-12 bg-black border-0 p-3 rounded text-white"
                      >
                        Valider
                      </button>
                    </div>
                  </form>
                ) : (
                  <div className="my-3">
                    <Message variant={"alert-warning"}>
                      Veuillez-vous
                      <Link to="/login">
                        " <strong>Login</strong> "
                      </Link>{" "}
                      pour commenter
                    </Message>
                  </div>
                )}
              </div>
            </div> */}
          </>
        )}
      </div>
    </Box>
  );
};

export default SingleFlashSale;
