import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listFlashSale } from "../../Redux/Actions/FlashsaleActions";

function Dots({ activeIndex, onClick, imageSlider }) {

    return (
        <div className="all-dots">
            {imageSlider?.map((slide, index) => (
                <span
                    key={index}
                    className={`${activeIndex === index ? "dot active-dot" : "dot"}`}
                    onClick={() => onClick(index)}
                ></span>
            ))}
        </div>
    )
}

export default Dots;