import React from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "./styles.css";

export const Footer = () => {
  const history = useHistory();
  const listflashSale = useSelector((state) => state.flashList);
  const { flashsales } = listflashSale || {};

  const handleClickEmail = () => {
    history.push("envoyer-email");
  };
  return (
    <>
      <footer>
        <div className="container">
          <div className="sec aboutus">
            <h2>A propos</h2>
            <p className="footText">
              Société de création de produits en Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique
            </p>
            <ul className="sci">
              <li onClick={handleClickEmail}>
                <a href="https://csm.sifaka.mg@gmail.com">
                  <i className="fas fa-at"></i>
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/Sifaka.mg">
                  <i className="fab fa-facebook"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="sec quickLinks">
            <h2>Categories</h2>
            <ul>
              <li>
                <Link to={`/haute-couture`}>Haute-couture</Link>
              </li>
              <li>
                <Link to={`/deco-interieure`}>Déco intérieure</Link>
              </li>
              <li>
                <Link to={`/menuiserie-bois`}>Menuiserie bois</Link>
              </li>
              <li>
                <Link to={`/menuiserie-metallique`}>Menuiserie métallique</Link>
              </li>
            </ul>
          </div>
          {flashsales?.length ? (
            <div className="sec offer">
              <h2>Nos offres</h2>
              <ul>
                <li>
                  <Link to={`/vente-flash`}>Vente promotion</Link>
                </li>
              </ul>
            </div>
          ) : (
            <></>
          )}
          {/* <div className="sec contact">
            <h2>Contact Info</h2>
            <ul className="info">
              <li>
                <span>
                  <i className="fas fa-map-marker-alt"></i>
                </span>
                <span>
                  101, Ambatomaro
                  <br />
                  Antananarivo,
                  <br />
                  Madagascar
                </span>
              </li>
              <li>
                <span>
                  <i className="fas fa-phone-alt"></i>
                </span>
                <p>
                  <a href="tel:+261 34 14 452 08">+261 34 14 452 08</a>
                </p>
              </li>
              <li onClick={handleClickEmail}>
                <span>
                  <i className="fas fa-envelope"></i>
                </span>
                <p>
                  <a href="https://csm.sifaka.mg@gmail.com"> csm.sifaka.mg@gmail.com</a>
                </p>
              </li>
            </ul>
          </div> */}
        </div>
      </footer>
      <div className="copyrightText">
        <p>
          <Link className="navbar-brand" to="/">
            <img alt="logo" src="/images/sifaka_logo.png" />
          </Link>

          <span>
            &copy; {new Date().getFullYear()}, All Reserved By
            <span>
              <a
                href="https://www.linkedin.com/in/rasolonjatovo-martino-2a4841151/"
                target="_blank"
                rel="noreferrer"
                className="author"
              >
                Martino
              </a>
            </span>
          </span>
        </p>
      </div>
    </>
  );
};
