import {
  FLASHSALE_DETAILS_REQUEST,
  FLASHSALE_DETAILS_SUCCESS,
  FLASHSALE_LIST_FAIL,
  FLASHSALE_LIST_REQUEST,
  FLASHSALE_LIST_SUCCESS,
} from "../Constants/FlashSaleConstants";

// FLASHSALE LIST
export const flashsaleListReducer = (state = { flashsales: [] }, action) => {
  switch (action.type) {
    case FLASHSALE_LIST_REQUEST:
      return { loading: true, flashsales: [] };
    case FLASHSALE_LIST_SUCCESS:
      return {
        loading: false,
        flashsales: action.payload,
      };
    case FLASHSALE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// SINGLE FLASH SALE
export const flashsaleDetailsReducer = (state = { flashsale: {} }, action) => {
  switch (action.type) {
    case FLASHSALE_DETAILS_REQUEST:
      return { ...state, loading: true };
    case FLASHSALE_DETAILS_SUCCESS:
      return { loading: false, flashsale: action.payload };
    case FLASHSALE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
