import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { savePaymentMethod } from "../Redux/Actions/CartActions";
import Header from "./../components/Header";
import { Helmet } from 'react-helmet';

const PaymentScreen = ({ history }) => {
  window.scrollTo(0, 0);

  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  if (!shippingAddress) {
    history.push("/livraison");
  }

  const [paymentMode] = useState("MVola");

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(savePaymentMethod(paymentMode));
    history.push("/destination");
  };

  return (
    <>
      <Helmet>
        <title>sifaka.mg</title>
        <meta
          name="sifaka.mg"
          content="Sifaka ecommerce, Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne, produits artisanals"
        />
        <description>Sifaka ecommerce, Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne, produits artisanals</description>
      </Helmet>
      <Header />
      <div className="container d-flex justify-content-center align-items-center login-center">
        <form
          className="Login2 col-md-8 col-lg-4 col-11"
          onSubmit={submitHandler}
        >
          <h6>MODE DE PAYEMENT</h6>
          <div className="payment-container">
            {/* <div className="radio-container">
              <input
                className="form-check-input"
                type="radio"
                value={paymentMode}
                onChange={(event) => setPaymentMode(event.target.value)}
              />
              <label className="form-check-label">MVOLA</label>
            </div>
            ou{" "} */}
            <Link className="col-md-6 paiementCash">
              <Typography>Payer cash</Typography>
            </Link>
          </div>

          <button type="submit">Continue</button>
        </form>
      </div>
    </>
  );
};

export default PaymentScreen;
