import {
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
} from "../Constants/ProductConstants";
import axios from "axios";
import { logout } from "./UserActions";

// PRODUCT LIST
export const listProduct =
  (searchText = "", pageNumber = "", parentId = "") =>
  async (dispatch) => {
    const URL = process.env.REACT_APP_URI
    try {
      dispatch({ type: PRODUCT_LIST_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "content-type": "application/x-www-form-urlencoded",
          "Access-Control-Allow-Credentials": "true",
        },
      };

      const { data } = await axios.get(
        `${URL}/api/products/list?searchText=${searchText}&pageNumber=${pageNumber}&parentId=${parentId}`,
        config
      );
      dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// SINGLE PRODUCT
export const listProductDetails = (id) => async (dispatch) => {
  const URL = process.env.REACT_APP_URI
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Credentials": "true",
      },
    };

    const { data } = await axios.get(
      `${URL}/api/products/${id}`,
      config
    );
    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// PRODUCT REVIEW CREATE
export const createProductReview =
  (productId, review) => async (dispatch, getState) => {
    const URL = process.env.REACT_APP_URI
    try {
      dispatch({ type: PRODUCT_CREATE_REVIEW_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
          accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "content-type": "application/x-www-form-urlencoded",
          "Access-Control-Allow-Credentials": "true",
        },
      };

      await axios.post(
        `${URL}/api/products/${productId}/review`,
        review,
        config
      );
      dispatch({ type: PRODUCT_CREATE_REVIEW_SUCCESS });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Non autoriser, invalide token") {
        dispatch(logout());
      }
      dispatch({
        type: PRODUCT_CREATE_REVIEW_FAIL,
        payload: message,
      });
    }
  };
