import React from "react";
import { Link } from "react-router-dom";
import ContactForm from "./components/ContactForm";

const SendEmail = () => {
  return (
    <div className="h-screen">
      <div className="laptop:grid laptop:grid-cols-2 lg:gap-6 bg-green-200 lg:h-2/3">
        <div className="flex flex-col justify-center text-center lg:p-40 md:text-left">
          <p className="uppercase font-medium opacity-40 text-red-900 font-extrabold">
            Notre contact
          </p>
          <div className="flex justify-center">
            <Link className="navbar-brand" to="/">
              <img
                src="/images/sifaka_logo.png"
                // className="w-16 h-16 object-cover"
                alt="logo"
                style={{ width: "200px", height: "92px" }}
              />
            </Link>
          </div>
          <h1 className="lg:text-5xl md:text-5xl text">Discutons-nous</h1>
        </div>
        <ContactForm />
      </div>
    </div>
  );
};

export default SendEmail;
