import React, { useEffect } from "react";
import SliderContent from "./SliderContent";
// import imageSlider from './imageSlider'
import Arrows from "./Arrows";
import Dots from "./Dots";
import './slider.css'
import { useDispatch, useSelector } from "react-redux";
import { listFlashSale } from "../../Redux/Actions/FlashsaleActions";

function Slider(props) {
    const [activeIndex, setActiveIndex] = React.useState(0);

    const dispatch = useDispatch();

    const flashList = useSelector((state) => state?.flashList?.flashsales);
    const imageSlider  = flashList || [];
    const len = imageSlider?.length - 1;

    useEffect(() => {
        dispatch(listFlashSale());
    }, [dispatch]);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
        }, 5000);
        return () => clearInterval(interval)
    }, [activeIndex])

    return (
        <div className="slider-container">
            <SliderContent activeIndex={activeIndex} imageSlider={imageSlider} />
            <Arrows
                prevSlide={() => setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)}
                nextSlide={() => setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)}
            />
            <Dots
                activeIndex={activeIndex}
                imageSlider={imageSlider}
                onClick={(activeIndex) => setActiveIndex(activeIndex)}
            />
        </div>
    )
}

export default Slider