import {
  Box,
  Button,
  createStyles,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { Close, Phone } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { listCategory } from "../../Redux/Actions/CategoryActions";

const useStyles = makeStyles(() =>
  createStyles({
    fab: {
      position: "absolute",
      right: 33,
      top: 26,
    },
    drawer: {
      '& [class*="MuiDrawer-paper"]': {
        width: "100%",
      },
    },
    list: {
      width: 250,
    },
    fullList: {
      width: "auto",
    },
    contact: {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
      marginTop: "-8px",
    },
    button: {
      marginLeft: 4,
      '& [class*="MuiButton-label"]': {
        fontSize: 14,
        textTransform: "initial",
      },
    },
    link: {
      textDecoration: "none",
      color: "#000",
      "&:hover": {
        color: "#000",
      },
    },
    listItem: {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
    listIcon: {
      '&[class*="MuiListItemIcon-root"]': {
        display: "flex",
        alignItems: "center",
        color: "#212121",
        marginRight: 4,
        '@media (max-width:780px)': {
          fontSize: 12
        }
      },
      '& [class*="MuiSvgIcon-root"]': {
        width: 16,
        height: 16,
        marginRight: 4,
      },
    },
    tel: {
      '& [class*="MuiTypography-root"]': {
        '@media (max-width:780px)': {
          fontSize: 12,
          fontFamily: 'Roboto',
          fontWeight: 600,
          color: '#212121'
        }
      },
    },
    listMenu: {
      minWidth: 230,
      "& .MuiList-root": {
        display: "flex",
        flexDirection: "column",
      },
    },
  })
);

export const NavLinksMobile = (props) => {
  const { handleCategoryClick } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const categoryLists = useSelector((state) => state.categoryList);
  const category = categoryLists?.categories?.categoryList;

  useEffect(() => {
    dispatch(listCategory());
  }, [dispatch]);

  //   console.log("*****CAT****", category);

  const [state, setState] = useState({
    menu: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const listflashSale = useSelector((state) => state.flashList);
  const { flashsales } = listflashSale || {};

  return (
    <>
      {["menu"].map((anchor, index) => (
        <React.Fragment key={index}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            classes={{ root: classes.drawer }}
          >
            <IconButton
              classes={{ root: classes.fab }}
              onClick={toggleDrawer(anchor, false)}
            >
              <Close />
            </IconButton>
            {category?.map((cat, index) => (
              <div key={index} className="min-h-24">
                <div className="px-3 text-left md:cursor-pointer">
                  <h1>{cat?.name}</h1>
                </div>
                <div>
                  {cat?.children?.map((slink, index) => (
                    <li
                      className="ml-7 list-none"
                      onClick={() => {
                        handleCategoryClick(slink?.name);
                        setState({ ...state, [anchor]: false });
                      }}
                      key={index}
                    >
                      {slink?.name}
                    </li>
                  ))}
                </div>
              </div>
            ))}
            <Box className={classes.listMenu}>
              <List component="nav" aria-label="secondary mailbox folders">
                {flashsales?.length ? (
                  <ListItem button classes={{ root: classes.contact }}>
                    <ListItemText>
                      <Button
                        variant="outlined"
                        classes={{ root: classes.button }}
                      >
                        <Link to={`/vente-flash`} className={classes.link}>
                          Vente flash
                        </Link>
                      </Button>
                    </ListItemText>
                  </ListItem>
                ) : (
                  <></>
                )}
                <ListItem
                  button
                  classes={{ root: classes.listItem }}
                ></ListItem>
                <ListItem>
                  <ListItemIcon classes={{ root: classes.listIcon }}>
                    <Phone /> Service commerciale :
                  </ListItemIcon>
                  <ListItemText classes={{ root: classes.tel }}>034 11 651 68</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{ root: classes.listIcon }}>
                    <Phone /> Direction :
                  </ListItemIcon>
                  <ListItemText classes={{ root: classes.tel }}>034 14 452 08</ListItemText>
                </ListItem>
              </List>
            </Box>
          </Drawer>
        </React.Fragment>
      ))}
    </>
  );
};
