import {
  SIFAKA_ANIMATION_FAIL,
  SIFAKA_ANIMATION_REQUEST,
  SIFAKA_ANIMATION_SUCCESS,
} from "../Constants/AnimationSifaakaConstants";

// SIFAKA ANIMATION LIST
export const sifakaAnimationReducer = (
  state = { animationSifaka: [] },
  action
) => {
  switch (action.type) {
    case SIFAKA_ANIMATION_REQUEST:
      return { loading: true, animationSifaka: [] };
    case SIFAKA_ANIMATION_SUCCESS:
      return {
        loading: false,
        animationSifaka: action.payload,
      };
    case SIFAKA_ANIMATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
