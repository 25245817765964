import {
  Box,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Header from "../components/Header";
import ShopSection from "../components/homeComponents/ShopSection";
import { listCategory } from "../Redux/Actions/CategoryActions";
import { listProduct } from "../Redux/Actions/ProductActions";
import { Helmet } from 'react-helmet';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
    },
    header: {
      width: "100%",
      display: "flex",
      padding: 8,
    },
    title: {
      margin: "auto",
      fontSize: 28,
      fontFamily: "Roboto",
      fontWeight: 600,
      color: "#892a10",
    },
    body: {
      display: "flex",
    },
    bodyItem: {
      display: "flex",
      width: "80%",
    },
    buttons: {
      width: "20%",
      display: "flex",
      flexDirection: "column",
    },
    button: {
      marginBottom: 16,
    },
  })
);

const DecoInterieurScreen = ({ match }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const categoryLists = useSelector((state) => state.categoryList);
  const category = categoryLists?.categories?.categoryList;

  const searchText = match.params.searchText;
  const pageNumber = match.params.pageNumber;
  const parentId = match.params.parentId;

  // console.log("Categories**********", category);

  useEffect(() => {
    dispatch(listCategory());
  }, [dispatch]);

  useEffect(() => {
    dispatch(listProduct(searchText, pageNumber, parentId));
  }, [dispatch, searchText, pageNumber, parentId]);

  const handleCategoryClick = (parentId) => {
    history.push(`/parentId/${parentId}`);
  };

  const handleGoBack = () => {
    history.push("/");
  };

  return (
    <Box className={classes.root}>
      <Helmet>
        <title>sifaka.mg</title>
        <meta
          name="sifaka.mg"
          content="Sifaka ecommerce, Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne, produits artisanals"
        />
        <description>Sifaka ecommerce, Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne, produits artisanals</description>
      </Helmet>
      <Header />
      <Box className={classes.header}>
        <IconButton onClick={handleGoBack}>
          <ArrowBack />
        </IconButton>
        <Typography classes={{ root: classes.title }}>
          Deco-interieure
        </Typography>
      </Box>
      <Box className={classes.body}>
        <Box className={classes.buttons}>
          {category
            ?.find((el) => el.slug === "Deco-interieure")
            ?.children?.map((item) => (
              <Button
                variant="outlined"
                key={item._id}
                onClick={() => handleCategoryClick(item?.name)}
                classes={{ root: classes.button }}
              >
                {item?.name}
              </Button>
            ))}
        </Box>
        <Box className={classes.bodyItem}>
          <ShopSection
            searchText={searchText}
            pageNumber={pageNumber}
            parentId={parentId}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DecoInterieurScreen;
