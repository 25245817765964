import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Message from "../components/LoadingError/Error";
import Loading from "../components/LoadingError/Loading";
import { login } from "../Redux/Actions/UserActions";
import { Helmet } from 'react-helmet';

const useStyles = makeStyles(() =>
  createStyles({
    goBack: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "& .MuiSvgIcon-root": {
        width: 20,
        color: "#7a7a7a",
      },
    },
    setting: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: 16,
    },
    register: {
      "& > a": {
        color: "#2bde1a",
        textDecoration: "none",
        fontSize: 18,
        fontFamily: "Roboto",
        fontWeight: 600,
      },
    },
    home: {
      marginTop: "unset !important",
      color: "#7a7a7a",
      fontSize: 16,
      fontFamily: "Roboto",
      marginLeft: 8,
    },
    textRegister: {
      fontSize: 12,
      fontFamily: "Roboto",
      fontWeight: 600,
      "@media (max-width:1424px)": {
        fontSize: 11,
      },
    },
  })
);

const Login = ({ location, history }) => {
  window.scrollTo(0, 0);
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const redirect = location.search ? location.search.split("=")[1] : "/";
  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    }
  }, [userInfo, history, redirect]);

  const HandleSubmit = (event) => {
    event.preventDefault();
    // console.log("LOG", email);
    dispatch(login(email, password));
  };

  const handleGoback = () => {
    history.push("/");
  };

  return (
    <>
      <Helmet>
        <title>sifaka.mg</title>
        <meta
          name="sifaka.mg"
          content="Sifaka ecommerce, Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne, produits artisanals"
        />
        <description>Sifaka ecommerce, Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne, produits artisanals</description>
      </Helmet>
      {/* <Header /> */}
      <div className="container d-flex flex-column justify-content-center align-items-center login-center">
        {error && <Message variant="alert-danger">{error}</Message>}
        {loading && <Loading />}
        <form
          className="Login col-md-8 col-lg-4 col-11"
          onSubmit={HandleSubmit}
        >
          <Box className={classes.setting}>
            <Box className={classes.goBack} onClick={handleGoback}>
              <ArrowBack />
              <Typography classes={{ root: classes.home }}>Accueil</Typography>
            </Box>
          </Box>
          <Link className="navbar-brand" to="/">
            <img alt="logo" src="/images/sifaka_logo.png" />
          </Link>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            className="email"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <button type="submit">Connexion</button>
        </form>
        <div className="boxRegister col-md-8 col-lg-4 col-11">
          <Typography classes={{ root: classes.textRegister }}>
            Si vous n'avez pas encore de compte veuillez créer votre compte en
            appuyant ici
          </Typography>
          <Typography classes={{ root: classes.register }}>
            <Link
              to={
                redirect
                  ? `/creer-compte?redirect=${redirect}`
                  : "/creer-compte"
              }
            >
              Creer un compte
            </Link>
          </Typography>
        </div>
      </div>
    </>
  );
};

export default Login;
