import axios from "axios";
import {
  SIFAKA_ANIMATION_FAIL,
  SIFAKA_ANIMATION_SUCCESS,
} from "../Constants/AnimationSifaakaConstants";

export const listAnimationSifaka = () => async (dispatch) => {
  const URL = process.env.REACT_APP_URI;
  try {
    dispatch({
      type: SIFAKA_ANIMATION_SUCCESS,
    });

    const { data } = await axios.get(
      `${URL}/api/animationSifaka/animation-sifaka-client`
    );
    // console.log("ANIMATION_SIFAKA_ACTION", data);
    dispatch({
      type: SIFAKA_ANIMATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SIFAKA_ANIMATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
