import axios from "axios";
import {
  ANIMATION_FLASHSALE_FAIL,
  ANIMATION_FLASHSALE_SUCCESS,
} from "../Constants/AnimationFlashSale";

export const listAnimationFlsahSale = () => async (dispatch) => {
  const URL = process.env.REACT_APP_URI;
  try {
    dispatch({
      type: ANIMATION_FLASHSALE_SUCCESS,
    });

    const { data } = await axios.get(
      `${URL}/api/animationFlashSale/animation-vente-client`
    );
    // console.log("ANIMATION_FLASHSALE_ACTION", data);
    dispatch({
      type: ANIMATION_FLASHSALE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ANIMATION_FLASHSALE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
