import React from "react";

const InputField = (props) => {
  const { label, name, type, value, placeholder } = props;
  return (
    <div>
      <label className="text-gray-500 text-sm" htmlFor={name}>
        {label}
      </label>
      <input
        className="border-b-2 outline-none text-gray-700 w-full bg-gray-100 h-10 px-2"
        type={type}
        value={value}
        name={name}
        placeholder={placeholder}
        required
      />
    </div>
  );
};

export default InputField;
