import { createStyles, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
// import Rating from "./Rating";
import Pagination from "./pagination";
import { useSelector } from "react-redux";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";
import { Visibility } from "@material-ui/icons";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: 38,
      fontFamily: "Roboto",
      fontWeight: 600,
      "@media (max-width: 1980)": {
        marginRight: 320,
      },
      "@media (max-width: 1660)": {
        marginRight: 260,
      },
      "@media (max-width: 1460)": {
        marginRight: 216,
      },
      "@media (max-width: 1312)": {
        marginRight: 204,
      },
      "@media (max-width: 1306)": {
        marginRight: 180,
      },
      "@media (max-width: 1024)": {
        marginRight: 140,
      },
    }
  })
);

const ShopSection = (props) => {
  const classes = useStyles();
  const { searchText } = props;
  const productList = useSelector((state) => state.productList);
  const { loading, error, products, page, pages } = productList;

  // console.log("<<<<<<<<<<<<<<<<<<DATA>>>>>>>>>>>>>>>>>>>>>>>>>", products);

  // useEffect(() => {
  //   dispatch(listProduct(searchText, pagenumber, parentId));
  // }, [dispatch, searchText, pagenumber, parentId]);

  return (
    <>
      {products?.length ? (
        <div className="container">
          <div className="section">
            <div className="row">
              <div className="col-lg-12 col-md-12 article">
                <div className="shopcontainer row">
                  {loading ? (
                    <div className="mb-5">
                      <Loading />
                    </div>
                  ) : error ? (
                    <Message variant="alert-danger">{error}</Message>
                  ) : (
                    <>
                      {products.map((product) => (
                        <div
                          className="shop col-lg-4 col-md-6 col-sm-6"
                          key={product._id}
                        >
                          <div className="border-product">
                            {product.ref ? <h4>Ref : {product.ref}</h4> : null}

                            <Link to={`/produit/${product._id}`}>
                              <div className="shopBack">
                                <Tooltip title="Voir détail">
                                  <IconButton id="visibility">
                                    <Visibility />
                                  </IconButton>
                                </Tooltip>
                                <img src={product.image} alt={product.name} />
                              </div>
                            </Link>

                            <div className="shoptext">
                              <p>
                                <Link to={`/products/${product._id}`}>
                                  {product.name}
                                </Link>
                              </p>

                              {/* <Rating
                                value={product.rating}
                                text={`${product.numReviews} Vote(s)`}
                              /> */}
                              <h3>{product.price} Ar</h3>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}

                  {/* Pagination */}
                  <Pagination
                    pages={pages}
                    page={page}
                    searchText={searchText ? searchText : ""}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loading />
          </div>
          <Typography className={classes.root}>
            Produit non disponible
          </Typography>
        </>
      )}
    </>
  );
};

export default ShopSection;
