import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useHistory } from "react-router-dom";
import InputField from "./InputField";
import TextareaField from "./TextareaField";

const ContactForm = () => {
  const form = useRef();
  const history = useHistory();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_zhqfvgi",
        "template_t0p20rh",
        form.current,
        "9fx13b62yDzlL-59G"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  const handleGoback = () => {
    history.push("/");
  };
  return (
    <div className="lg:mt-8 lg:mr-48 md:mt-8 md:mr-28 laptop:mt-8 laptop:mr-28 pt-6 pb-8 bg-white shadow-xl rounded p-5">
      <form ref={form} onSubmit={sendEmail}>
        <h3 className="text-red-900 mb-7 text-xl font-semibold">
          Envoyez-nous un message
        </h3>
        <InputField
          label="Objet :"
          type="text"
          placeholder="Votre motif"
          name="subject"
        />
        <InputField
          label="Nom :"
          type="text"
          placeholder="Votre nom"
          name="user_name"
        />
        <InputField
          label="Email :"
          type="email"
          placeholder="Votre email"
          name="user_email"
        />
        <TextareaField
          label="Message :"
          name="message"
          placeholder="Votre message...."
        />
        <div className="flex justify-between text-center mt-8">
          <p
            onClick={handleGoback}
            className="text-red-900 cursor-pointer font-bold"
          >
            Accueil
          </p>
          <button className="bg-green-400 p-2 rounded text-red-900">
            Envoyer
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
