import React, { useEffect } from "react";
import Header from "./../components/Header";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "../Redux/Actions/CartActions";
import {
  Box,
  createStyles,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { Helmet } from 'react-helmet';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      overflow: "auto",
      height: "calc(100vh - 116px)",
    },
    cartItem: {
      maxWidth: "98%",
      margin: "auto",
      boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
      display: "flex",
      marginBottom: 16,
      padding: 8,
    },
    cartItems: {
      height: "calc(100vh - 415px)",
      overflowY: "scroll",
      padding: 10,
    },
    varification: {
      // width: '10%'
      background: '#1cb803',
      color: '#fff',
      padding: 6,
      borderRadius: 8
    },
    achat: {
      // width: '12%'
      background: '#212121',
      color: '#fff',
      padding: 6,
      borderRadius: 8
    },
    boxBtns: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end'
    }
  })
);

const CartScreen = ({ history, match, location }) => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:900px)");
  window.scrollTo(0, 0);
  const dispatch = useDispatch();
  const productId = match.params.id;
  const qty = location.search ? Number(location.search.split("=")[1]) : 1;

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const total = cartItems.reduce((a, i) => a + i.qty * i.price, 0).toFixed(2);

  // console.log(productId);

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty));
    }
  }, [dispatch, productId, qty]);

  const handlecheckOut = () => {
    history.push("/login?redirect=livraison");
  };

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  return (
    <>
      <Helmet>
        <title>sifaka.mg</title>
        <meta
          name="sifaka.mg"
          content="Sifaka ecommerce, Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne, produits artisanals"
        />
        <description>Sifaka ecommerce, Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne, produits artisanals</description>
      </Helmet>
      <Header />
      {/* Cart */}
      <div className="container">
        {cartItems.length === 0 ? (
          <div className=" alert alert-info text-center mt-3">
            Votre cart est vide
            <Link
              className="btn btn-success mx-5 px-5 py-3"
              to="/"
              style={{
                fontSize: "12px",
              }}
            >
              ACHETER MAINTENANT
            </Link>
          </div>
        ) : (
          <Box className={classes.root}>
            <div className=" alert alert-info text-center mt-3">
              Total des Produicts dans la cart
              <Link className="text-success mx-2" to="/cart">
                ({cartItems.length})
              </Link>
            </div>
            {/* cartiterm */}
            <Box className={matches && classes.cartItems}>
              {cartItems.map((item, index) => (
                <div className="cart-iterm row cart-row" key={index}>
                  <div
                    onClick={() => removeFromCartHandler(item.product)}
                    className="remove-button d-flex justify-content-center align-items-center"
                  >
                    <i className="fas fa-times"></i>
                  </div>
                  <div className="cart-image col-md-3">
                    <img src={item.image} alt={item.name} />
                  </div>
                  <div className="cart-text col-md-5 d-flex align-items-center">
                    <Link
                      to={`/produit/${item.product}`}
                      className="productName"
                    >
                      <h4>{item.name}</h4>
                    </Link>
                  </div>
                  <div className="cart-qty col-md-2 col-sm-5 mt-3 mt-md-0 d-flex flex-column justify-content-center">
                    <h6>QUANTITE</h6>
                    <select
                      value={item.qty}
                      onChange={(e) =>
                        dispatch(
                          addToCart(item.product, Number(e.target.value))
                        )
                      }
                    >
                      {[...Array(item.countInStock).keys()].map((x) => (
                        <option key={x + 1} value={x + 1}>
                          {x + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="cart-price mt-3 mt-md-0 col-md-2 align-items-sm-end align-items-start  d-flex flex-column justify-content-center col-sm-7">
                    <h6>Prix</h6>
                    <h4>{item.price}Ar</h4>
                  </div>
                </div>
              ))}
            </Box>

            {/* End of cart iterms */}
            <div className="total">
              <span className="sub">total:</span>
              <span className="total-price">{total}Ar</span>
            </div>
            <hr />
            <div className={classes.boxBtns}>
              <Link to="/">
                <button className={classes.achat}>Continuer l'achat</button>
              </Link>
              {total > 0 && (
                <button
                  className={classes.varification}
                  onClick={handlecheckOut}
                >
                  Vérification
                </button>
              )}
            </div>
          </Box>
        )}
      </div>
    </>
  );
};

export default CartScreen;
