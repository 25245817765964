import { Box, createStyles, Fab, makeStyles } from "@material-ui/core";
// import NavigationIcon from "@material-ui/icons/Navigation";
import React, { useEffect } from "react";
import Header from "./../components/Header";
import ShopSection from "./../components/homeComponents/ShopSection";
import ContactInfo from "./../components/homeComponents/ContactInfo";
import CalltoActionSection from "./../components/homeComponents/CalltoActionSection";
import { Footer } from "../components/Footer/Footer";
import { Navigation } from "../components/Navigation/Navigation";
import { useDispatch, useSelector } from "react-redux";
import { listProduct } from "../Redux/Actions/ProductActions";
// import CarouselContainer from "../components/Categories/AllProduct";
// import { Slider } from "../components/FlashSale/Slider";
import { useHistory } from "react-router-dom";
import { AddShoppingCart } from "@material-ui/icons";
import { listAnimationFlsahSale } from "../Redux/Actions/AnimationFlashSaleActions";
import { listAnimationSifaka } from "../Redux/Actions/AnimationSifakaActions";
import { Helmet } from 'react-helmet';
import Slider from "../components/Slider/Slider";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    slideProduct: {
      width: "100%",
      minWidth: "800px",
    },
    slide: {
      width: "50%",
      minWidth: "400px",
    },
    carouselSlideClassName: {
      "&.carousel": {
        width: "526px !important",
        height: "434px !important",
        margin: "auto",
        "@media (max-width:1084px)": {
          width: "368px !important",
          height: "368px !important",
        },
      },
      "& .carousel-inner": {
        height: "100%",
      },
    },
    boxFab: {
      display: "flex",
      flexDirection: "column",
      "@media (max-width:950px)": {
        display: "none",
      },
    },
    extendedIcon: {
      // marginRight: "2px",
    },
    btnFab: {
      width: 170,
      background: "#355421",
      position: "fixed",
      top: 206,
      right: 44,
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      "&:hover": {
        color: "#507f32",
        background: "#f3fbf7",
        "& .MuiFab-label": {
          color: "#507f32",
        },
        "& .MuiSvgIcon-root": {
          color: "#507f32",
        },
      },
      "& .MuiFab-label": {
        alignItems: "center",
        fontSize: 12,
        fontFamily: "Roboto",
        fontWeight: 500,
        marginLeft: 8,
        color: "#fff",
      },
      "& .MuiSvgIcon-root": {
        width: 18,
        height: 18,
        color: "#fff",
        marginRight: 8,
      },
      "@media(max-width:1424px)": {
        right: 30,
        top: 226,
      },
      "@media(max-width:1306px)": {
        top: 208,
      },
      "@media (max-width:400px)": {
        right: 6,
      },
    },
  })
);

const HomeScreen = ({ match }) => {
  window.scrollTo(0, 0);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const searchText = match.params.searchText;
  const pageNumber = match.params.pageNumber;
  const parentId = match.params.parentId;

  const listflashSale = useSelector((state) => state.flashList);
  const { flashsales } = listflashSale || {};

  const animationListFlsahSale = useSelector(
    (state) => state.animationFlashSaleList
  );
  const { animationFlashSale } = animationListFlsahSale || {};

  const animationListSifaka = useSelector((state) => state.animationSifakaList);
  const { animationSifaka } = animationListSifaka || {};

  // console.log("ANIMATION_FLASHSALE_CLIENT", animationFlashSale);
  // console.log("animationSifaka", animationSifaka);

  useEffect(() => {
    dispatch(listProduct(searchText, pageNumber, parentId));
  }, [dispatch, searchText, pageNumber, parentId]);

  useEffect(() => {
    dispatch(listAnimationFlsahSale());
  }, [dispatch]);

  useEffect(() => {
    dispatch(listAnimationSifaka());
  }, [dispatch]);

  const handleCategoryClick = (parentId) => {
    history.push(`/parentId/${parentId}`);
  };

  const handleFlashSaleClick = () => {
    history.push("/vente-flash");
  };

  return (
    <div>
      <Helmet>
        <title>sifaka.mg</title>
        <meta
          name="sifaka.mg"
          content="Sifaka ecommerce, Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne, produits artisanals"
        />
        <description>Sifaka ecommerce, Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne, produits artisanals</description>
      </Helmet>
      {flashsales?.length ? (
        <>
          <div className={`${flashsales?.length === 0 ? "nopub" : "vente-flash"}`}>
            {animationFlashSale &&
              animationFlashSale?.map((animation) => (
                <marquee style={{ color: '#FFF' }} /* className="txt" key={animation?._id} */>
                  {animation?.animation}
                </marquee>
              ))}
          </div>
        </>
      ) : (
        ""
      )}
      {animationSifaka?.length ? (
        <>
          <div className={`${animationSifaka?.length === 0 ? "nopub" : "vente-flash"}`}>
            {animationSifaka &&
              animationSifaka?.map((animation) => (
                <marquee style={{ color: '#FFF' }} /* className="txt" key={animation?._id} */>
                  {animation?.animation}
                </marquee>
              ))}
          </div>
        </>
      ) : (
        <></>
      )}
      <Header handleCategoryClick={handleCategoryClick} />
      <Navigation handleCategoryClick={handleCategoryClick} />
      <div className="section-scroll">
        <Box>
          <ShopSection
            searchText={searchText}
            pageNumber={pageNumber}
            parentId={parentId}
          />
          {flashsales?.length ? <Slider /> : <></>}
          <CalltoActionSection />
          <ContactInfo />
          <Footer />
        </Box>
        {/* <Box className={classes.boxFab}>
          <Fab
            variant="extended"
            classes={{ root: classes.btnFab }}
            onClick={handleFlashSaleClick}
          >
            <AddShoppingCart className={classes.extendedIcon} />
            Vente promo
          </Fab>
        </Box> */}
        {flashsales?.length ? (
          <Box className={classes.boxFab}>
            <Fab
              variant="extended"
              classes={{ root: classes.btnFab }}
              onClick={handleFlashSaleClick}
            >
              <AddShoppingCart className={classes.extendedIcon} />
              Vente promo
            </Fab>
          </Box>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default HomeScreen;
