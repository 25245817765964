import { Box, Button, makeStyles, Menu, MenuItem } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listCategory } from "../../Redux/Actions/CategoryActions";
import classnames from "classnames";

const useStyles = makeStyles({
  menu: {
    "&.MuiMenu-list": {
      minWidth: "120px !important",
    },
  },
  button: {
    marginLeft: 4,
    '&[class*="MuiButtonBase-root"]': {
      background: "#892a10",
      color: "#fff",
    },
    // '&[class*="MuiButton-outlined"]': {
    //   border: "1px solid burlywood",
    // },
  },
});

export const SelectCouture = (props) => {
  const { buttonClassName, handleCategoryClick } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const categoryLists = useSelector((state) => state.categoryList);
  const category = categoryLists?.categories?.categoryList;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [id, setId] = useState();

  useEffect(() => {
    dispatch(listCategory());
  }, [dispatch]);

  // const findUrl = category?.find((el) => el.name === 'Haute-couture' ? `/haute-couture` : undefined)

  function handleClick(event, id) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
    setId(id);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  // console.log("*****CAT****", category);

  return (
    <>
      {category?.map((cat, index) => (
        <Box key={index}>
          <Button
            variant="contained"
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={(e) => handleClick(e, cat._id)}
            // onMouseOver={(e) => handleClick(e, cat._id)}
            endIcon={<ExpandMore />}
            classes={{ root: classnames(classes.button, buttonClassName) }}
            // style={{ backgroundColor: cat?.bgColor }}
          >
            {cat?.name}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && id === cat._id && cat?.children?.length}
            onClose={handleClose}
            MenuListProps={{ onMouseLeave: handleClose }}
            classes={{ list: classes.menu }}
          >
            {cat.children.map((ch, index) => (
              <MenuItem
                onClick={() => handleCategoryClick(ch?.name)}
                key={index}
              >
                {ch.name}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      ))}
    </>
  );
};
