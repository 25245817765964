import React from "react";
import "./App.css";
import "./index.css";
import "./responsive.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import SingleProduct from "./screens/SingleProduct";
import Login from "./screens/Login";
import Register from "./screens/Register";
import CartScreen from "./screens/CartScreen";
import ShippingScreen from "./screens/ShippingScreen";
import ProfileScreen from "./screens/ProfileScreen";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import NotFound from "./screens/NotFound";
import PrivateRouter from "./PrivateRouter";
import SingleFlashSale from "./screens/SingleFlashSale";
import FlashSaleScreen from "./screens/FlashSaleScreen";
import ContactScreen from "./screens/ContactScreen";
import HauteCoutureScreen from "./screens/HauteCoutureScreen";
import DecoInterieurScreen from "./screens/DecoInterieurScreen";
import MenuiserieBoisScreen from "./screens/MenuiserieBoisScreen";
import MenuiserieMetalliqueScreen from "./screens/MenuiserieMetalliqueScreen";
import SendEmail from "./screens/SendEmailScreen/SendEmail";
import { Helmet } from 'react-helmet';

const App = () => {
  return (
    <div>
      <Helmet>
        <title>Sifaka</title>
        <description>Sifaka E-commerce Société de création de produits en Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne pour des produits artisanals</description>
        <meta
          name="Sifaka E-commerce Société de création de produits en Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne pour des produits artisanals"
          content="Sifaka E-commerce Société de création de produits en Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne pour des produits artisanals"
        />
      </Helmet>
      <Router>
        <Switch>
          <Route path="/parentId/:parentId" component={HomeScreen} exact />
          <Route path="/recherche/:searchText" component={HomeScreen} exact />
          <Route path="/page/:pageNumber" component={HomeScreen} exact />
          <Route
            path="/recherche/:searchText/page/:pageNumber"
            component={HomeScreen}
            exact
          />
          <Route path="/" component={HomeScreen} exact />
          <Route path="/contact" component={ContactScreen} />
          <Route path="/produit/:id" component={SingleProduct} />
          <Route path="/vente-flash/:id" component={SingleFlashSale} />
          <Route path="/vente-flash" component={FlashSaleScreen} />
          <Route path="/haute-couture" component={HauteCoutureScreen} />
          <Route path="/deco-interieure" component={DecoInterieurScreen} />
          <Route path="/menuiserie-bois" component={MenuiserieBoisScreen} />
          <Route
            path="/menuiserie-metallique"
            component={MenuiserieMetalliqueScreen}
          />
          <Route path="/login" component={Login} />
          <Route path="/creer-compte" component={Register} />
          <PrivateRouter path="/profile" component={ProfileScreen} />
          <Route path="/cart/:id?" component={CartScreen} />
          <PrivateRouter path="/livraison" component={ShippingScreen} />
          <PrivateRouter path="/payment" component={PaymentScreen} />
          <PrivateRouter path="/destination" component={PlaceOrderScreen} />
          <PrivateRouter path="/commande/:id" component={OrderScreen} />
          <Route path="/envoyer-email" component={SendEmail} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
