import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Header from "../components/Header";
import ContactInfo from "../components/homeComponents/ContactInfo";
import { Navigation } from "../components/Navigation/Navigation";
import { Helmet } from 'react-helmet';

const useStyles = makeStyles({
  body: {
    width: "100%",
    padding: 12,
  },
  title: {
    display: "flex",
    justifyContent: "center",
    fontSize: 34,
    fontFamily: "Roboto",
    fontWeight: 600,
    color: "#892a10",
  },
});

const ContactScreen = (props) => {
  const { showAceuil } = props;
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Helmet>
        <title>sifaka.mg</title>
        <meta
          name="sifaka.mg"
          content="Sifaka ecommerce, Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne, produits artisanals"
        />
        <description>Sifaka ecommerce, Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne, produits artisanals</description>
      </Helmet>
      <Header />
      <Navigation showAceuil={showAceuil} />
      <Box className={classes.body}>
        <Typography classes={{ root: classes.title }}>Nos Contacts</Typography>
        <ContactInfo />
      </Box>
    </Box>
  );
};

export default ContactScreen;
