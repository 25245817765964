import axios from "axios";
import {
  FLASHSALE_DETAILS_FAIL,
  FLASHSALE_DETAILS_REQUEST,
  FLASHSALE_DETAILS_SUCCESS,
  FLASHSALE_LIST_FAIL,
  FLASHSALE_LIST_REQUEST,
  FLASHSALE_LIST_SUCCESS,
} from "../Constants/FlashSaleConstants";

// FLASH SALE LIST
export const listFlashSale =
  (searchText = "", pageNumber = "") =>
  async (dispatch) => {
    const URL = process.env.REACT_APP_URI;
    try {
      dispatch({ type: FLASHSALE_LIST_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(`${URL}/api/venteflashs`, config);
      console.log('data', data)

      dispatch({ type: FLASHSALE_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: FLASHSALE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// SINGLE Flash Sale
export const listFlashsaleDetails = (id) => async (dispatch) => {
  const URL = process.env.REACT_APP_URI;
  try {
    dispatch({ type: FLASHSALE_DETAILS_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`${URL}/api/venteflashs/${id}`, config);
    dispatch({ type: FLASHSALE_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: FLASHSALE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
