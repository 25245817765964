import {
  AppBar,
  Button,
  makeStyles,
  Toolbar,
  useMediaQuery,
} from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { listFlashSale } from "../../Redux/Actions/FlashsaleActions";
import { SelectCouture } from "./SelectMenu";
import "./styles.css";

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
  },
  appBarRoot: {
    background: "#de6b1a",
    display: "flex",
    "@media (max-width:1084px)": {
      display: "none",
    },
  },
  toolbar: {
    "&.MuiToolbar-root": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
  buttonVente: {
    marginLeft: 4,
    '& [class*="MuiButton-label"]': {
      fontSize: 14,
      textTransform: "initial",
    },
  },
  button: {
    marginLeft: 4,
    '& [class*="MuiButton-label"]': {
      fontSize: 14,
      textTransform: "initial",
    },
  },
  link: {
    textDecoration: "none",
    color: "#000",
    "&:hover": {
      color: "#000",
    },
  },
  navBtn: {
    visibility: "hidden",
    opacity: 0,
  },
  menuMobile: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  listMenu: {
    minWidth: 230,
    "& .MuiList-root": {
      display: "flex",
      flexDirection: "column",
    },
  },
  buttonClassName: {
    marginBottom: 8,
    "& :last-child": {
      marginBottom: "0px !important",
    },
  },
  listItem: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
  contact: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    marginTop: "-8px",
  },
  contactList: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 8,
  },
  contactListItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  contactText: {
    color: "#212121",
    fontSize: 14,
  },
  contactTel: {
    color: "#212121",
    marginLeft: 8,
    fontSize: 14,
    fontWeight: 600,
  },
  contactMobile: {
    "@media (max-width:1024px)": {
      display: "flex",
    },
  },
  popoverRoot: {
    '& [class*="MuiPaper-root"]': {
      left: "138px !important",
    },
    "@media (max-width:950px)": {
      '& [class*="MuiPaper-root"]': {
        left: "80px !important",
      },
    },
  },
  listIcon: {
    '&[class*="MuiListItemIcon-root"]': {
      display: "flex",
      alignItems: "center",
      color: "#212121",
      marginRight: 4,
    },
    '& [class*="MuiSvgIcon-root"]': {
      width: 16,
      height: 16,
      marginRight: 4,
    },
  },
  goBack: {
    "@media (max-width:600px)": {
      marginLeft: "-40px",
    },
  },
});

export const Navigation = (props) => {
  const { showAceuil, handleCategoryClick } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const listflashSale = useSelector((state) => state.flashList);
  const { flashsales } = listflashSale || {};
  

  // const history = useHistory();
  const matches = useMediaQuery("(max-width:1084px)");

  // const [anchorEl, setAnchorEl] = useState(null);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;

  // const handleGoback = () => {
  //   history.push("/");
  // };

  useEffect(() => {
    dispatch(listFlashSale());
  }, [dispatch]);

  // console.log("*****VENTE PROMO*****", flashsales);

  return (
    <AppBar position="static" classes={{ root: classes.appBarRoot }}>
      {matches ? (
        <></>
      ) : (
        // <Box className={classes.menuMobile}>
        //   <IconButton onClick={handleGoback} classes={{ root: classes.goBack }}>
        //     <ArrowBack />
        //     <Typography>
        //       {matchesMobile ? "Retour" : "Retour au menu principal"}
        //     </Typography>
        //   </IconButton>
        //   <Box className={classes.contactMobile}>
        //     <Box>
        //       <IconButton onClick={handleClick}>
        //         <Menu />
        //         <Typography>{matchesMobile ? "Menu" : "Liste menu"}</Typography>
        //       </IconButton>
        //       <Popover
        //         id={id}
        //         open={open}
        //         anchorEl={anchorEl}
        //         onClose={handleClose}
        //         anchorOrigin={{
        //           vertical: "bottom",
        //           horizontal: "center",
        //         }}
        //         transformOrigin={{
        //           vertical: "top",
        //           horizontal: "center",
        //         }}
        //         classes={{ root: classes.popoverRoot }}
        //       >
        //         <Box className={classes.listMenu}>
        //           <List component="nav" aria-label="secondary mailbox folders">
        //             <ListItem
        //               button
        //               onClick={handleClose}
        //               classes={{ root: classes.listItem }}
        //             >
        //               <ListItemText>
        //                 {showAceuil && (
        //                   <Button variant="outlined">
        //                     <Link to={`/`} className={classes.link}>
        //                       Accueil
        //                     </Link>
        //                   </Button>
        //                 )}
        //               </ListItemText>
        //             </ListItem>
        //             <ListItem
        //               button
        //               onClick={handleClose}
        //               classes={{ root: classes.listItem }}
        //             >
        //               <ListItemText>
        //                 <SelectCouture
        //                   handleCategoryClick={handleCategoryClick}
        //                   buttonClassName={classes.buttonClassName}
        //                 />
        //               </ListItemText>
        //             </ListItem>
        //             {/* {flashsales?.length ? (
        //               <ListItem
        //                 button
        //                 onClick={handleClose}
        //                 classes={{ root: classes.contact }}
        //               >
        //                 <ListItemText>
        //                   <Button
        //                     variant="outlined"
        //                     classes={{ root: classes.button }}
        //                   >
        //                     <Link to={`/vente-flash`} className={classes.link}>
        //                       Vente flash
        //                     </Link>
        //                   </Button>
        //                 </ListItemText>
        //               </ListItem>
        //             ) : (
        //               <></>
        //             )} */}
        //             <ListItem
        //               button
        //               onClick={handleClose}
        //               classes={{ root: classes.listItem }}
        //             >
        //               {/* <ListItemText>
        //                 <Button
        //                   variant="outlined"
        //                   classes={{ root: classes.button }}
        //                 >
        //                   <Link to={`/contact`} className={classes.link}>
        //                     Contact
        //                   </Link>
        //                 </Button>
        //               </ListItemText> */}
        //             </ListItem>
        //             <ListItem>
        //               <ListItemIcon classes={{ root: classes.listIcon }}>
        //                 <Phone /> Commercial :
        //               </ListItemIcon>
        //               <ListItemText>034 11 651 68</ListItemText>
        //             </ListItem>
        //             <ListItem>
        //               <ListItemIcon classes={{ root: classes.listIcon }}>
        //                 <Phone /> Direction :
        //               </ListItemIcon>
        //               <ListItemText>034 14 452 08</ListItemText>
        //             </ListItem>
        //           </List>
        //         </Box>
        //       </Popover>
        //     </Box>
        //     {/* <Box className={classes.contactList}>
        //       <Box className={classes.contactListItem}>
        //         <Typography classes={{ root: classes.contactText }}>
        //           Contact Commercial :
        //         </Typography>
        //         <Typography classes={{ root: classes.contactTel }}>
        //           034 11 651 68
        //         </Typography>
        //       </Box>
        //       <Box className={classes.contactListItem}>
        //         <Typography classes={{ root: classes.contactText }}>
        //           Contact Direction :
        //         </Typography>
        //         <Typography classes={{ root: classes.contactTel }}>
        //           034 14 452 08
        //         </Typography>
        //       </Box>
        //     </Box> */}
        //   </Box>
        // </Box>
        <Toolbar classes={{ root: classes.toolbar }}>
          {showAceuil && (
            <Button variant="outlined">
              <Link to={`/`} className={classes.link}>
                Accueil
              </Link>
            </Button>
          )}
          <SelectCouture handleCategoryClick={handleCategoryClick} />
          {/* {flashsales?.length ? (
            <Button variant="outlined" classes={{ root: classes.buttonVente }}>
              <Link to={`/vente-flash`} className={classes.link}>
                Vente flash
              </Link>
            </Button>
          ) : (
            <></>
          )} */}
          {/* <Button variant="outlined" classes={{ root: classes.button }}>
            <Link to={`/contact`} className={classes.link}>
              Contact
            </Link>
          </Button>
          <Box className={classes.contactList}>
            <Box className={classes.contactListItem}>
              <Typography classes={{ root: classes.contactText }}>
                Contact Commercial :
              </Typography>
              <Typography classes={{ root: classes.contactTel }}>
                034 11 651 68
              </Typography>
            </Box>
            <Box className={classes.contactListItem}>
              <Typography classes={{ root: classes.contactText }}>
                Contact Direction :
              </Typography>
              <Typography classes={{ root: classes.contactTel }}>
                034 14 452 08
              </Typography>
            </Box>
          </Box> */}
        </Toolbar>
      )}
    </AppBar>
  );
};
