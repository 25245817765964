import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Footer } from "../components/Footer/Footer";
import Header from "../components/Header";
import ContactInfo from "../components/homeComponents/ContactInfo";
import Pagination from "../components/homeComponents/pagination";
import Message from "../components/LoadingError/Error";
import Loading from "../components/LoadingError/Loading";
import { Navigation } from "../components/Navigation/Navigation";
import { listFlashSale } from "../Redux/Actions/FlashsaleActions";
import { Helmet } from 'react-helmet';


const FlashSaleScreen = (props) => {
  const { searchText, pagenumber } = props;
  const [showAceuil] = useState(true);
  const dispatch = useDispatch();
  const listflashSale = useSelector((state) => state.flashList);
  const { loading, error, page, pages, flashsales } = listflashSale || {};

  useEffect(() => {
    dispatch(listFlashSale(searchText, pagenumber));
  }, [dispatch, searchText, pagenumber]);

  return (
    <Box>
     <Helmet>
        <title>sifaka.mg</title>
        <meta
          name="sifaka.mg"
          content="Sifaka ecommerce, Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne, produits artisanals"
        />
        <description>Sifaka ecommerce, Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne, produits artisanals</description>
      </Helmet>
      <Header />
      <Navigation showAceuil={showAceuil} />
      <h3 className="flashsale">Ventes promotion</h3>
      <div className="section-scroll-flashsale">
        <div className="container">
          <div className="section">
            <div className="row">
              <div className="col-lg-12 col-md-12 article">
                <div className="shopcontainer row">
                  {loading ? (
                    <div className="mb-5">
                      <Loading />
                    </div>
                  ) : error ? (
                    <Message variant="alert-danger">{error}</Message>
                  ) : (
                    <>
                      {flashsales.map((flashsale) => (
                        <div
                          className="shop col-lg-4 col-md-6 col-sm-6"
                          key={flashsale?._id}
                        >
                          <div className="border-product">
                            <Link to={`/vente-flash/${flashsale?._id}`}>
                              <div className="shopBack">
                                <img
                                  src={flashsale?.image}
                                  alt={flashsale?.name}
                                />
                              </div>
                            </Link>

                            <div className="shoptext">
                              <p>
                                <Link to={`/vente-flash/${flashsale?._id}`}>
                                  {flashsale?.name}
                                </Link>
                              </p>

                              {/* <Rating
                              value={product.rating}
                              text={`${product.numReviews} revue(s)`}
                            /> */}
                              <h3>{flashsale?.price} Ar</h3>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}

                  {/* Pagination */}
                  <Pagination
                    pages={pages}
                    page={page}
                    searchText={searchText ? searchText : ""}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactInfo />
        <Footer />
      </div>
    </Box>
  );
};

export default FlashSaleScreen;
