import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  productCreateReviewReducer,
  productDetailsReducer,
  productListReducer,
} from "./Reducers/ProductReducers";
import { cartReducer } from "./Reducers/CartReducers";
import {
  userDetailsReducer,
  userLoginReducer,
  userRegisterReducer,
  userUpdateProfileReducer,
} from "./Reducers/UserReducers";
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderListMyReducer,
  orderPayReducer,
} from "./Reducers/OrderReducers";
import { categoryListReducer } from "./Reducers/CategoryReducers";
import {
  flashsaleDetailsReducer,
  flashsaleListReducer,
} from "./Reducers/FlashsaleReducers";
import { flashsaleAnimationReducer } from "./Reducers/AnimationFlashSaleReducers";
import { sifakaAnimationReducer } from "./Reducers/AnimationSifakaReducers";

const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productReviewCreate: productCreateReviewReducer,
  cart: cartReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderListMy: orderListMyReducer,
  categoryList: categoryListReducer,
  flashList: flashsaleListReducer,
  flashsaleDetails: flashsaleDetailsReducer,
  animationFlashSaleList: flashsaleAnimationReducer,
  animationSifakaList: sifakaAnimationReducer,
});

const cartItemsFromLocalStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

// LOGIN
const userInfoFromLocalStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

// SHIPPING_ADDRESS
const storage = localStorage.getItem("shippingAddress") || null;
// console.log("STORAGE", storage);
const shippingAddressFromLocalStorage =
  !!storage && storage !== `undefined` ? JSON.parse(storage) : {};

const initialState = {
  cart: {
    cartItems: cartItemsFromLocalStorage,
    shippingAddress: shippingAddressFromLocalStorage,
  },
  userLogin: {
    userInfo: userInfoFromLocalStorage,
  },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
