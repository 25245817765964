import React, { useEffect, useState } from "react";
import Header from "./../components/Header";
import Rating from "../components/homeComponents/Rating";
import { Link } from "react-router-dom";
import Message from "./../components/LoadingError/Error";
import { useDispatch, useSelector } from "react-redux";
import {
  createProductReview,
  listProductDetails,
} from "../Redux/Actions/ProductActions";
import Loading from "../components/LoadingError/Loading";
import { PRODUCT_CREATE_REVIEW_RESET } from "../Redux/Constants/ProductConstants";
import moment from "moment";
import { Button } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { Helmet } from 'react-helmet';

const SingleProduct = ({ history, match }) => {
  const dispatch = useDispatch();
  const [qty, setQty] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const productId = match.params.id;
  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const {
    loading: loadingCreateReview,
    // error: errorCreateReview,
    success: successCreateReview,
  } = productReviewCreate;

  useEffect(() => {
    if (successCreateReview) {
      setRating(0);
      setComment("");
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
    }
    dispatch(listProductDetails(productId));
  }, [dispatch, productId, successCreateReview]);

  const handleAddCart = (event) => {
    event.preventDefault();
    history.push(`/cart/${productId}?qty=${qty}`);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    dispatch(
      createProductReview(productId, {
        rating,
        comment,
      })
    );
  };

  const handleGoBack = () => {
    history.push('/')
  }

  return (
    <>
      <Helmet>
        <title>sifaka.mg</title>
        <meta
          name="sifaka.mg"
          content="Sifaka ecommerce, Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne, produits artisanals"
        />
        <description>Sifaka ecommerce, Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne, produits artisanals</description>
      </Helmet>
      <Header />
      <div className="container single-product">
        {loading ? (
          <div className="mb-5">
            <Loading />
          </div>
        ) : error ? (
          <Message variant="alert-danger">{error}</Message>
        ) : (
          <>
            <div className="row">
              <div className="col-md-6">
                <div className="single-image">
                  <img src={product.image} alt={product.name} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="product-dtl">
                  <div className="product-info">
                    <div className="product-name">{product.name}</div>
                  </div>
                  <p>{product.description}</p>

                  <div className="product-count detailProduct ">
                    {product?.ref ? (
                      <div className="flex-box d-flex justify-content-between align-items-center">
                        <h6>Référence</h6>
                        <span>{product.ref}</span>
                      </div>
                    ) : null}
                    <div className="flex-box d-flex justify-content-between align-items-center">
                      <h6>Prix</h6>
                      <span>{product.price} Ar</span>
                    </div>
                    <div className="flex-box d-flex justify-content-between align-items-center">
                      <h6>Statut</h6>
                      {product.countInStock > 0 ? (
                        <span>Disponible</span>
                      ) : (
                        <span>invalide</span>
                      )}
                    </div>
                    {product.countInStock > 0 ? (
                      <>
                        <div className="flex-box d-flex justify-content-between align-items-center">
                          <h6>Quantité(s)</h6>
                          <select
                            value={qty}
                            onChange={(event) => setQty(event.target.value)}
                          >
                            {[...Array(product.countInStock).keys()].map(
                              (x) => (
                                <option key={x + 1} value={x + 1}>
                                  {x + 1}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        <div className="box-btn">
                          <Button className="btnAccueil" onClick={handleGoBack} startIcon={<ArrowBack />}>Accueil</Button>
                          <button
                            onClick={handleAddCart}
                            className="round-black-btn"
                          >
                            Ajouter au panier
                          </button>
                        </div>

                        <div className="col-md-6 boxNote">
                          {/* <h6>Evaluation</h6>
                          <div className="my-4">
                            {loadingCreateReview && <Loading />}
                            {errorCreateReview && (
                              <Message>{errorCreateReview}</Message>
                            )}
                          </div> */}

                          {userInfo ? (
                            <></>
                          ) : (
                            // <form onSubmit={submitHandler}>
                            //   <div className="my-4">
                            //     <strong>Notation</strong>
                            //     <select
                            //       value={rating}
                            //       onChange={(event) =>
                            //         setRating(event.target.value)
                            //       }
                            //       className="col-12 bg-light p-3 mt-2 border-0 rounded select"
                            //     >
                            //       <option value="">Choisir...</option>
                            //       <option value="1">1 - Mauvais</option>
                            //       <option value="2">2 - Acceptable</option>
                            //       <option value="3">3 - Bien</option>
                            //       <option value="4">4 - Tres bien</option>
                            //       <option value="5">5 - Excellent</option>
                            //     </select>
                            //   </div>
                            //   <div className="my-4">
                            //     <strong>Commentaire</strong>
                            //     <textarea
                            //       row="3"
                            //       value={comment}
                            //       onChange={(event) =>
                            //         setComment(event.target.value)
                            //       }
                            //       className="col-12 bg-light p-3 mt-2 border-0 rounded"
                            //     ></textarea>
                            //   </div>
                            //   <div className="my-3">
                            //     <button
                            //       disabled={loadingCreateReview}
                            //       className="col-12 bg-black border-0 p-3 rounded text-white"
                            //     >
                            //       Envoyer
                            //     </button>
                            //   </div>
                            // </form>
                            <div className="my-3">
                              <Message variant={"alert-warning seconnect"} className="alert">
                                Veuillez-vous
                                <Link to="/login" className="note">
                                  {" "}
                                  "<strong> se connecter </strong>"
                                </Link>{" "}
                                s'il vous plaît!
                              </Message>
                            </div>
                          )}
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            {/* RATING */}
            <div className="row my-5">
              <div className="col-md-6 comment">
                {/* <h6 className="mb-3">Vote(s)</h6> */}
                {/* {product?.reviews.length === 0 && (
                    <Message variant={"alert-info mt-3 seconnect"}>
                    Veuillez connecter pour donner votre avis
                  </Message>
                )} */}

                {product?.reviews.map((review) => (
                  <div
                    key={review._id}
                    className="mb-5 mb-md-3 bg-light p-3 shadow-sm rounded"
                  >
                    <strong>{review?.name}</strong>
                    <Rating value={review?.rating} />
                    <span>
                      {moment(review?.createdAt).add(3, "days").format("ll")}
                    </span>
                    <div className="alert alert-info mt-3">
                      {review?.comment}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SingleProduct;
