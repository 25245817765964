import {
  ANIMATION_FLASHSALE_FAIL,
  ANIMATION_FLASHSALE_REQUEST,
  ANIMATION_FLASHSALE_SUCCESS,
} from "../Constants/AnimationFlashSale";

// FLASHSALE ANIMATION LIST
export const flashsaleAnimationReducer = (
  state = { animationFlashSale: [] },
  action
) => {
  switch (action.type) {
    case ANIMATION_FLASHSALE_REQUEST:
      return { loading: true, animationFlashSale: [] };
    case ANIMATION_FLASHSALE_SUCCESS:
      return {
        loading: false,
        animationFlashSale: action.payload,
      };
    case ANIMATION_FLASHSALE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
