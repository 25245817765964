import React from "react";

const TextareaField = (props) => {
  const { label, name, placeholder } = props;
  return (
    <div>
      <label className="text-gray-500 text-sm" htmlFor={name}>
        {label}
      </label>
      <textarea
        name={name}
        rows={3}
        className="w-full border-b-2 outline-none bg-gray-100 p-2"
        required
        placeholder={placeholder}
      />
    </div>
  );
};

export default TextareaField;
