import React from "react";

function SliderContent({ activeIndex, imageSlider }) {
    return (
        <section>
            {imageSlider?.map((slide, index) => (
                <div
                    key={index}
                    className={index === activeIndex ? "slides active" : "inactive"}
                >
                    <img className="slide-image" src={slide?.image} alt="photo" />
                    <h2 className="slide-title">{slide?.name}</h2>
                    <h3 className="slide-text">{slide?.description}</h3>
                </div>
            ))}
        </section>
    )
}

export default SliderContent