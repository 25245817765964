import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { logout } from "../Redux/Actions/UserActions";
import { Badge, Box, Button, makeStyles } from "@material-ui/core";
// import { NavLinks } from "./Dropdown/NavLinks";
import { Person, ShoppingCart } from "@material-ui/icons";
import { NavLinksMobile } from "./Dropdown/NavLinksMobile";
import { Helmet } from 'react-helmet';

const useStyles = makeStyles({
  register: {
    marginRight: 8,
    minWidth: 145,
    "& a": {
      textDecoration: "none",
    },
  },
  login: {
    marginRight: 8,
    minWidth: 94,
    '&[class*="MuiButtonBase-root"]': {
      background: "#892a10",
      color: "#fff",
    },
    '& [class*="MuiSvgIcon-root"]': {
      color: "#fff",
    },
    "& a": {
      textDecoration: "none",
    },
    '&[class*="MuiButton-outlined"]': {
      border: "1px solid #892a10",
    },
    "&:hover": {
      background: "#f3fbf7",
      '&[class*="MuiButton-outlined"]': {
        border: "1px solid #1cb803",
      },
      '& [class*="MuiSvgIcon-root"]': {
        color: "#1cb803",
      },
    },
  },
  boxMenu: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    "@media(max-width:1424px)": {
      width: "45%",
    },
    "@media(max-width:1306px)": {
      width: "44%",
    },
  },
  boxRight: {
    width: "50%",
    display: "flex",
    justifyContent: "space-between",
    "@media(max-width:1424px)": {
      width: "54%",
      // justifyContent: "unset !important",
    },
    "@media(max-width:1306px)": {
      width: "54%",
      justifyContent: "space-between !important",
    },
  },
  badge: {
    '& [class*="MuiBadge-colorSecondary"]': {
      backgroundColor: "#38b000",
    },
    "&:hover": {
      '& [class*="MuiBadge-colorSecondary"]': {
        backgroundColor: "#8b5e34",
      },
    },
    '& [class*="MuiSvgIcon-root"]': {
      color: "#892a10",
      fontSize: 34,
      "&:hover": {
        color: "#38b000",
      },
    },
  },
});

const Header = (props) => {
  const { handleCategoryClick } = props;
  const classes = useStyles();
  const [searchText, setSearchText] = useState();
  const dispatch = useDispatch();
  let history = useHistory();

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleSearch = (event) => {
    event.preventDefault();
    if (searchText.trim()) {
      history.push(`/recherche/${searchText}`);
    } else {
      history.push("/");
    }
  };

  return (
    <div>
      <Helmet>
        <title>Sifaka</title>
        <description>Sifaka E-commerce Société de création de produits en Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne pour des produits artisanals</description>
        <meta
          name="Sifaka E-commerce Société de création de produits en Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne pour des produits artisanals"
          content="Sifaka E-commerce Société de création de produits en Haute couture, Déco intérieure, Menuiserie bois, Menuiserie métallique. Achat enligne pour des produits artisanals"
        />
      </Helmet>
      {/* Header */}
      <div className="header">
        <div className="container">
          {/* MOBILE HEADER */}
          <div className="mobile-header">
            <div className="container ">
              <div className="row ">
                <div className="col-6 d-flex align-items-center">
                  <Link className="navbar-brand" to="/">
                    <img alt="logo" src="/images/sifaka_logo.png" />
                  </Link>
                </div>
                <div className="col-6 d-flex align-items-center Login-Register">
                  {userInfo ? (
                    <div className="btn-group">
                      <button
                        type="button"
                        className="name-button dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="fas fa-user"></i>
                      </button>
                      <div className="dropdown-menu">
                        <Link className="dropdown-item" to="/profile">
                          Profile
                        </Link>

                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={handleLogout}
                        >
                          Deconnection
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div className="btn-group">
                      <button
                        type="button"
                        className="name-button dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="fas fa-user"></i>
                      </button>
                      <div className="dropdown-menu">
                        <Link className="dropdown-item" to="/login">
                          Connexion
                        </Link>

                        <Link className="dropdown-item" to="/creer-compte">
                          Créer un compte
                        </Link>
                      </div>
                    </div>
                  )}

                  <Link to="/cart" className="cart-mobile-icon">
                    <Badge
                      badgeContent={cartItems.length}
                      color="secondary"
                      classes={{ root: classes.badge }}
                    >
                      <ShoppingCart />
                    </Badge>
                    {/* <i className="fas fa-shopping-bag"></i>
                    <span className="badge">{cartItems.length}</span> */}
                  </Link>
                </div>
                <div className="col-12 d-flex align-items-center search-text">
                  <form onSubmit={handleSearch} className="input-group">
                    <input
                      type="search"
                      className="form-control rounded search"
                      placeholder="Search"
                      onChange={(event) => setSearchText(event.target.value)}
                    />
                    <button type="submit" className="search-button">
                      <i className="fas fa-search"></i>
                    </button>
                  </form>
                  <NavLinksMobile handleCategoryClick={handleCategoryClick} />
                  {/* <IconButton>
                    <Menu />
                  </IconButton> */}
                </div>
              </div>
            </div>
          </div>

          {/* PC HEADER */}
          <div className="pc-header">
            <div className="row head-row">
              <Box className={classes.boxMenu}>
                <div className="logo">
                  <Link className="navbar-brand" to="/">
                    <img alt="logo" src="/images/sifaka_logo.png" />
                  </Link>
                </div>
                {/* <div className="col-md-3 col-4 d-flex align-items-center">
                  <Link className="navbar-brand" to="/">
                    <img alt="logo" src="/images/sifaka_logo.png" />
                  </Link>
                </div> */}
                {/* <NavLinks handleCategoryClick={handleCategoryClick} /> */}
              </Box>
              <Box className={classes.boxRight}>
                <div className="col-md-6 col-8 d-flex d-flex-search align-items-center">
                  <form onSubmit={handleSearch} className="input-group">
                    <input
                      type="search"
                      className="form-control rounded search"
                      placeholder="Recherche"
                      onChange={(event) => setSearchText(event.target.value)}
                    />
                    <button type="submit" className="search-button">
                      <i className="fas fa-search"></i>
                    </button>
                  </form>
                </div>
                <div className="col-md-3 d-flex align-items-center Login-Register">
                  {userInfo ? (
                    <div className="btn-group">
                      <button
                        type="button"
                        className="name-button dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Bonjour, {userInfo.name}
                      </button>
                      <div className="dropdown-menu">
                        <Link className="dropdown-item" to="/profile">
                          Profile
                        </Link>

                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={handleLogout}
                        >
                          Deconnexion
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <>
                      {/* <Button variant="outlined" className={classes.register}>
                        <Link to="/creer-compte">Créer un compte</Link>
                      </Button> */}
                      <Button
                        variant="contained"
                        className={classes.login}
                        startIcon={<Person />}
                      >
                        <Link to="/login">CONNEXION</Link>
                      </Button>
                    </>
                  )}

                  <Link to="/cart">
                    <Badge
                      badgeContent={cartItems.length}
                      color="secondary"
                      classes={{ root: classes.badge }}
                    >
                      <ShoppingCart />
                    </Badge>
                    {/* <i className="fas fa-shopping-bag"></i>
                    <span className="badge">{cartItems.length}</span> */}
                  </Link>
                </div>
              </Box>
            </div>
          </div>
        </div>
        {/* <div className="head-contact-root">
          <div className="head-contact">
            <span className="help-contact">
              Contacter notre service pour vos commandes
            </span>
            <div>
              <div>
                <span className="departement">Service commerciale :</span>
                <span className="contact-departement">034 11 651 68</span>
              </div>
              <div>
                <span className="departement">Direction :</span>
                <span className="contact-departement">034 14 452 08</span>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Header;
